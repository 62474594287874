const requestPersistentStorage = async () => {
    const result = await navigator.storage.persist();
    if (result) {
        console.log('Storage is persisted');
    } else {
        console.log('Storage is not persisted');
    }
}

const storeData = async (key: string, value: string) => {
    await requestPersistentStorage();
    localStorage.setItem(key, value);
}

const getData = async (key: string): Promise<string | null> => {
    return localStorage.getItem(key);
}

export { getData, storeData };