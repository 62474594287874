import React from 'react';
import { updatePageTitle } from '../../utils/PageUtils';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';

const AboutView = (): JSX.Element => {
    updatePageTitle('О программе');
    return (
        <Box>
            <Avatar sx={{ width: '10rem', height: '10rem', margin: 'auto', marginTop: '3em', marginBottom: '3em'}}>LJ</Avatar>

            <Typography variant="h6" color="inherit" component="div" sx={{padding: '1em'}}>
                Людмила Юсупжанова
            </Typography>
            <Typography variant="body1" color="inherit" component="div" sx={{padding: '1em'}}>
                Составитель календаря. <br />
                Практикующий астролог с более чем 20 летним опытом. Бывший преподаватель Рижской астрологической школы <a href="http://www.astroselena.lv/">AstroSelena</a>.
            </Typography>

            <Typography variant="body1" color="inherit" component="div" sx={{padding: '1em'}}>
                Индивидуальные консультации и обучение.
            </Typography>
            <Typography variant="h6" color="inherit" component="div" sx={{padding: '1em'}}>
                Контакты:
            </Typography>
            <Typography variant="body1" color="inherit" component="div" sx={{padding: '0.5em'}}>
                <a href="mailto:ludmila.ju@inbox.lv"><EmailIcon /> ludmila.ju@inbox.lv</a> <br />
            </Typography>
        </Box>
    );
};

export default AboutView;