import React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

interface Props {
    text: string;
}

const NotSubscribed = (props: Props): JSX.Element => {
    const navigate = useNavigate();
    const toSubscription = () => {
        navigate('/subscribe')
    };
    return (
        <>
            {props.text}
            <Button onClick={toSubscription}>Подписка</Button>
        </>
    )
};

export default NotSubscribed;