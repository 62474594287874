import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const getResources = async () => {
    return {
        "ru": await import("./ru.json"),
        "en": await import("./en.json")
    };
}

const initializer = async () => {
  i18n
  .use(initReactI18next)
  .init({
    resources: await getResources(),
    lng: "ru",
    interpolation: {
      escapeValue: false
    }
  });
}

export default i18n;

export {initializer};