import {Month} from '../models/CalendarData'

const data = [
    import('./data/calendar-2024-09.json'),
    import('./data/calendar-2024-10.json'),
    import('./data/calendar-2024-11.json'),
    import('./data/calendar-2024-12.json'),
    // import('./data/calendar-2025-01.json')
];

const ensureLoadedData = async() => {
    return ((await Promise.all(data)).map(d => d.default)).flat();
}

const fetchData = async (year: number, month: number): Promise<Month> => {
    const loaded = await ensureLoadedData();
    const years = loaded.filter(y => y.year === year).map(y => y.months).flat() || [];
    const months = years.find(m => m.month === month) || {
        days: []
    };
    return {
        month: month,
        days: months.days
    } as Month;
};

const getMinMonth = async (): Promise<{year: number, month: number}> => {
    const loaded = await ensureLoadedData();
    let minYear = 2099;
    let minMonth = 12;
    loaded.forEach(year => {
        year.months.forEach(month => {
            if (year.year === minYear) {
                minMonth = Math.min(minMonth, month.month);
            } else if (year.year < minYear) {
                minYear = year.year;
                minMonth = month.month;
            }
        });
    })

    return {year: minYear, month: minMonth};
};

const getMaxMonth = async (): Promise<{year: number, month: number}> => {
    const loaded = await ensureLoadedData();
    let maxYear = 2024;
    let maxMonth = 1;
    loaded.forEach(year => {
        year.months.forEach(month => {
            if (year.year === maxYear) {
                maxMonth = Math.max(maxMonth, month.month);
            } else if (year.year > maxYear) {
                maxYear = year.year;
                maxMonth = month.month;
            }
        });
    })

    return {year: maxYear, month: maxMonth};
};

const dataAvailable = async ({year, month}: {year: number, month: number}): Promise<boolean> => {
    const asSingleNumber = (y: number, month: number): number => {
        return y * 20 + month;
    }
    const [max, min] = await Promise.all([getMaxMonth(), getMinMonth()]);
    return asSingleNumber(min.year, min.month) <= asSingleNumber(year, month) && 
        asSingleNumber(year, month) <= asSingleNumber(max.year, max.month);
};

export {
    fetchData,
    getMinMonth,
    getMaxMonth,
    dataAvailable
}