import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import CalendarIcon from '@mui/icons-material/CalendarMonth';
import EuroIcon from '@mui/icons-material/Euro';
import HelpIcon from '@mui/icons-material/ContactSupport';

import { useNavigate } from "react-router-dom";

import Share from '../Share';
import LanguageSelector from '../LanguageSelector';
import { toggles } from '../../features';
import i18n from '../../i18n';

const Header = (): JSX.Element => {
    const [open, setOpen] = useState(false);
    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };
    const navigate = useNavigate();
    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }}>
                        {i18n.t("general.Lunar Calendar")}
                    </Typography>
                    <Share />
                    <LanguageSelector />
                </Toolbar>
            </AppBar>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate("/")}>
                                <ListItemIcon>
                                    <CalendarIcon />
                                </ListItemIcon>
                                <ListItemText primary={i18n.t("menu.Calendar")} />
                            </ListItemButton>
                        </ListItem>
                        {toggles.subscriptions && <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate("/subscribe")}>
                                <ListItemIcon>
                                    <EuroIcon />
                                </ListItemIcon>
                                <ListItemText primary={i18n.t("menu.Subscription")} />
                            </ListItemButton>
                        </ListItem>}
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate("/about")}>
                                <ListItemIcon>
                                    <HelpIcon />
                                </ListItemIcon>
                                <ListItemText primary={i18n.t("menu.About")} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </>
    );
};

export default withTranslation()(Header);