import React from 'react';
import { Activity } from '../../models/CalendarData';
import Typography from '@mui/material/Typography';
import { formatActivity } from '../Calendar/Utils';

interface Props {
    activity: Activity;
    index: string;
    variant?: 'body1'|'body2';
}

const AstroActivity = (props: Props): JSX.Element => {
    return renderActivity(props.activity, props.index, props.variant);
};

const renderActivity = (r: Activity, index: string, variant: 'body1'|'body2' = 'body2'): JSX.Element => {
    let s = formatActivity(r.activity, r.time);
    const blocks: Array<{s: string, astro: boolean}> = [];
    while (true) {
        let i = s.indexOf("[");
        if (i >= 0) {
            let j = s.indexOf("]", i);
            blocks.push({s: s.substring(0, i), astro: true});
            blocks.push({s: s.substring(i + 1, j), astro: false});
            s = s.substring(j + 1);
        } else {
            blocks.push({s: s, astro: true});
            break;
        }
    }
    return (
        <Typography key={index} variant={variant} color="inherit" component="div" sx={{ fontFamily: 'astro', fontWeight: r.highlight ? 'bold' : 'normal' }}>
            {blocks.map((b, i) => renderBlock(b.s, b.astro, `block_${index}_${i}`, r.highlight, variant))}
        </Typography>
    );
}

const renderBlock = (s: string, astro: boolean, key: string, highlight: boolean, variant: 'body1'|'body2'): JSX.Element => {
    let sx: any = {};
    if (astro) {
        sx['fontFamily'] = 'astro';
    }
    if (highlight) {
        sx['fontWeight'] = 'bold';
    }
    return (
        <Typography key={key} variant={variant} color="inherit" component="span" sx={sx}>
            {s}
        </Typography>
    );

}

export default AstroActivity;