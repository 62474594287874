import React from 'react';
import { Day } from '../../models/CalendarData';
import Typography from '@mui/material/Typography';
import { formatActivity } from './Utils'

interface Props {
    day: number;
    data: Day;
}

const LunarDay = (props: Props): JSX.Element => {
    return (
        <div className='calendar-table-day'>
            <div className='calendar-table-content'>
                {props.data?.lunar_day?.map(day => (
                    <Typography key={`lunar-${props.day}-${day.day}`}variant="body1" color="inherit" component="div" textAlign="center" sx={{ fontFamily: 'astro' }}>
                        {formatActivity(day.day, day.time, ' → ')}
                    </Typography>
                ))}
            </div>
        </div>
    );
};

export default LunarDay;