import React, { useState, useEffect } from 'react';
import './App.css';
import Routing from './Routing';
import Header from './components/Header';
import InstallCard from './components/InstallCard';

import {
  HashRouter
} from "react-router-dom";

import {initializer} from './i18n';

const App = () => {
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    (async () => {
      await initializer();
      setInitialized(true);
    })();
  }, [])
  if (!initialized) {
    return <></>;
  }
  return (
    <div className="App">
      <HashRouter>
        <Header />
        <div className="content">
          <Routing />
        </div>
        <InstallCard />
      </HashRouter>
    </div>
  );
};

export default App;