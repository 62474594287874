import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import App from './App';
import { toggles } from './features';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { getSubscriptionStatus } from './services/SubscriptionService';

const isInstalled = async () => {
  const n = navigator as any;
  if (!n.getInstalledRelatedApps) {
    return false;
  }
  const relatedApps = await n.getInstalledRelatedApps();
  return relatedApps.length > 0;
};

(async () => {
  if (toggles.analytics) {
    const status = await getSubscriptionStatus();
    const installed = await isInstalled();
    ReactGA.initialize('G-RVZT4HBVZJ', {
      gtagOptions: {
        user_properties: {
          subscription_status: status,
          installation_status: installed
        },
        custom_map: {
          subscription_status: status,
          installation_status: installed
        },
        subscription_status: status,
        installation_status: installed
      }
    });
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
      }
    });
  }
})();


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
