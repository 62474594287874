import React, {useEffect} from 'react';
import ReactGA from 'react-ga4';
import CalendarView from './views/CalendarView';
import AboutView from './views/AboutView';
import SubscriptionView from './views/SubscriptionView';
import {toggles} from './features';

import {
    Routes,
    Route,
    useLocation
  } from "react-router-dom";
  
  const usePageTracking = () => {
    const location = useLocation();
  
    useEffect(() => {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }, [location]);
  };
  
const Routing = () => {
    usePageTracking();
    return (
        <Routes>
            {toggles.subscriptions && <Route path='/subscribe' element={<SubscriptionView />} />}
            <Route path='/about' element={<AboutView />} />
            <Route path='/calendar/:year/:month' element={<CalendarView />} />
            <Route path='/*' element={<CalendarView />}/>
        </Routes>
    );
};

export default Routing;