import { Phase } from '../../models/CalendarData';
import i18n from '../../i18n';

const getDaysArray = (year: number, month: number): Array<Array<number>> => {
    const daysInMonth = new Date(year, month, 0).getDate();
    let weekDayForFirstDay = new Date(year, month - 1, 1).getDay() - 1;
    if (weekDayForFirstDay < 0) {
        weekDayForFirstDay += 7;
    }
    const days = [];
    for (let i = 0; i < weekDayForFirstDay; i++) {
        days.push(0);
    }
    for (let i = 1; i <= daysInMonth; i++) {
        days.push(i);
    }
    while (days.length % 7 !== 0) {
        days.push(0);
    }
    return splitIntoWeeks(days);
};

const splitIntoWeeks = (days: Array<number>): Array<Array<number>> => {
    const result = new Array<Array<number>>();
    let week = new Array<number>();
    for (let i = 0; i < days.length; i++) {
        week.push(days[i]);
        if ((i + 1) % 7 === 0) {
            result.push(week);
            week = new Array<number>();
        }
    }
    if (week.length > 0) {
        result.push(week);
    }
    return result;
}

const formatActivity = (activity: any, time: string, joinChar: string = ' '): string => {
    if (!activity) {
        return '';
    }
    return [activity, time].filter(x => !!x).join(joinChar);
};

const mapMoonPhase = (phase: Phase) => {
    switch (phase) {
        case Phase.first_q: return '\u25D0'
        case Phase.full_moon: return '\u25EF'
        case Phase.last_q: return '\u25D1'
        case Phase.new_moon: return '\u2B24'
    }
}

const describePhase = (phase: Phase) => {
    switch (phase) {
        case Phase.first_q: return i18n.t('moon_phase.First Quarter')
        case Phase.full_moon: return i18n.t('moon_phase.Full Moon')
        case Phase.last_q: return i18n.t('moon_phase.Last Quarter')
        case Phase.new_moon: return i18n.t('moon_phase.New Moon')
    }
}

const describeEclipse = (eclipse?: 'moon' | 'sun'): string => {
    switch (eclipse) {
        case 'moon': {
            return i18n.t('eclipse.moon') + '.'; 
        }
        case 'sun': {
            return i18n.t('eclipse.sun') + '.'; 
        }
    }
    return '';
}

export { getDaysArray, formatActivity, mapMoonPhase, describePhase, describeEclipse };