import { getData, storeData } from './StorageService';
import { toggles } from '../features'

const key = 'AstroCalendarSubscriptionExpirationDate';

const getSubscriptionEndDate = async (): Promise<Date | null> => {
    let date = await getData(key);
    if (!date) {
        return new Date(2025, 0, 20);
    }
    return new Date(date);
};

const subscribeTill = async (till: Date) => {
    await storeData(key, till.toISOString())
};

const getSubscriptionStatus = async (): Promise<string> => {
    if (!toggles.subscriptions) {
        return 'N/A';
    } else if (!(await getData(key))) {
        return 'not_subscribed';
    } else {
        return 'subscribed';
    }
};

export { getSubscriptionEndDate, subscribeTill, getSubscriptionStatus };