import React from 'react';
import { Day, Activity } from '../../models/CalendarData';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { mapMoonPhase } from './Utils'
import AstroActivity from '../AstroActivity';

interface Props {
    day: number;
    isCurrent: boolean;
    isHoliday: boolean;
    data: Day;
    onDetailsClick: (day: Day) => void
}

const CalendarDay = (props: Props) => {
    const classes = ['calendar-table-date'];
    props.isCurrent && classes.push('today');
    props.isHoliday && classes.push('holiday');

    return props.day === 0 ? <div></div> : (
        <div className='calendar-table-day' onClick={() => props.onDetailsClick(props.data)}>
            <Typography variant="h5" color="inherit" component="div" className={classes.join(' ')}>
                {props.data.moon_phase && mapMoonPhase(props.data.moon_phase.phase)}
                &nbsp;
                {props.day}
            </Typography>

            {renderDetails(`day_${props.day}_moon`, props.data?.moon)}
            {props.data?.planets?.length > 0 && <Divider />}
            {renderDetails(`day_${props.day}_planet`, props.data?.planets)}
        </div>
    );
};

const renderDetails = (prefix: string, activities: Activity[]): JSX.Element => {
    return (
        <div className='calendar-table-content'>
            {activities?.map((r, index) => <AstroActivity key={`${prefix}_${index}`} activity={r} index={`${prefix}_${index}`}/>)}
        </div>
    );
}

export default CalendarDay;