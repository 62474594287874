import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { useParams, useNavigate, NavigateFunction } from 'react-router-dom';
import Calendar from '../../components/Calendar';
import './CalendarView.css'
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LeftIcon from '@mui/icons-material/ArrowCircleLeft';
import RightIcon from '@mui/icons-material/ArrowCircleRight';
import { capitalize } from '@mui/material/utils';

import { Day } from '../../models/CalendarData';
import { fetchData, dataAvailable, getMinMonth } from '../../services/CalendarDataService';
import { canViewMonth } from '../../services/PermissionsService';
import NotSubscribed from '../../components/NotSubscribed';

import { formatMonth, calculateNext } from '../../utils/DateUtils';
import { updatePageTitle } from '../../utils/PageUtils';

const navigateToMonth = (navigate: NavigateFunction, year: number, month: number) => {
    navigate(`/calendar/${year}/${month}`);
};

const CalendarView = () => {
    const [daysData, setDaysData] = useState<Array<Day>>([]);
    const [canView, setCanView] = useState(false);
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);

    const params = useParams();
    const navigate = useNavigate();
    const now = new Date();
    const year = Number(params['year']) || now.getFullYear();
    const month = Number(params['month']) || (now.getMonth() + 1);
    useEffect(() => {
        (async () => {
            if (! (await dataAvailable({year, month}))) {
                const mm = await getMinMonth();
                navigateToMonth(navigate, mm.year, mm.month);
                return;
            }
            setDaysData((await fetchData(year, month)).days);
            setCanView(await canViewMonth(year, month));
            setHasNext(await dataAvailable(calculateNext(year, month, 1)));
            setHasPrev(await dataAvailable(calculateNext(year, month, -1)));
            updatePageTitle(formatMonth(year, month))
        })();
    }, [year, month, params, navigate]);

    const changeMonth = (delta: number) => {
        ReactGA.event({
            category: 'User Engagement',
            action: delta > 0 ? 'next_month' : 'prev_month',
            label: delta > 0 ? 'Go to next month' : 'Got to prev month',
        });
        const next = calculateNext(year, month, delta);
        navigateToMonth(navigate, next.year, next.month);
    };

    return (
        <div className='calendar-view'>
            <Typography variant="h5" color="inherit" component="div">
                {hasPrev && <IconButton onClick={() => changeMonth(-1)}>
                    <LeftIcon />
                </IconButton>}
                {capitalize(formatMonth(year, month))}
                {hasNext && <IconButton onClick={() => changeMonth(1)}>
                    <RightIcon />
                </IconButton>}
            </Typography>
            {
                canView ?
                    <Calendar year={year} month={month} daysData={daysData} /> :
                    <NotSubscribed text="Вам требуется оформить подписку, чтобы видеть календарь на этот месяц." />
            }
        </div>
    );
};

export default withTranslation()(CalendarView);