import React from 'react';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import AstroActivity from '../AstroActivity';

import { mapMoonPhase as moonPhaseIcon, describePhase, describeEclipse } from '../Calendar/Utils';
import { formatDateSimple } from '../../utils/DateUtils';

import { Day, LunarDay, Activity, MoonPhase } from '../../models/CalendarData';

interface Props {
    year: number;
    month: number;
    day: Day;
}

const DayDetailsPopup = (props: Props): JSX.Element => {
    return (
        <Paper sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', padding: '1em'}}>
            <Typography variant="h5" color="inherit" component="div">
                {describeDay(props.year, props.month, props.day)}
            </Typography>
            <Typography variant="h6" color="inherit" component="div">
                Луна
            </Typography>
            <Typography variant="body1" color="inherit" component="div">
                {describeLunarDay(props.day)}
            </Typography>
            <div>
                {props.day.moon?.map((r, index) => <AstroActivity key={`moon_details_${index}`} activity={r} index={`moon_details_${index}`} variant={'body1'}/>)}
            </div>
            {props.day.moon_phase && (
                <Typography variant="body1" color="inherit" component="div">
                    {describeMoonPhase(props.day.moon_phase)}
                </Typography>
            )}
            <Typography variant="body1" color="inherit" component="div">
                {describeLunarActivities(props.day.moon)}
            </Typography>
            {props.day.planets?.length > 0 && (
                <>
                    <Divider />
                    <Typography variant="h6" color="inherit" component="div">
                        Планеты
                    </Typography>
                    <div>
                        {props.day.planets?.map((r, index) => <AstroActivity key={`planet_details_${index}`} activity={r} index={`planet_details_${index}`} variant={'body1'}/>)}
                    </div>
                    <Typography variant="body1" color="inherit" component="div">
                        {describePlanetsActivities(props.day.planets)}
                    </Typography>
                </>
            )}
        </Paper>
    );
};

const describeDay = (year: number, month: number, day: Day): string => {
    return formatDateSimple(year, month - 1, day.day);
};

const describeLunarDay = (day: Day): string => {
    const formatLunarDay = (ld: LunarDay): string => {
        if (!!ld.time) {
            return `${ld.day} лунный день начинается в ${ld.time}`
        } else {
            return `Продолжается ${ld.day} лунный день`
        }
    };

    const lunarDay = day.lunar_day;
    return lunarDay.map(formatLunarDay).join('. ') + '.';
};

const describeLunarActivities = (activities: Activity[]): string => {
    return activities?.map(describeLunarActivity).filter(s => s.length > 0).join('. ') + '.';
}

const describeLunarActivity = (activity: Activity): string => {
    const atTime = (activity: Activity): string => {
        return activity.time? ` в ${activity.time}` : '';
    };
    const constellations: any = {
        'a': 'Овна',
        'b': 'Тельца',
        'c': 'Близнецов',
        'd': 'Рака',
        'e': 'Льва',
        'f': 'Девы',
        'g': 'Весов',
        'h': 'Скорпиона',
        'i': 'Стрельца',
        'j': 'Козерога',
        'k': 'Водолея',
        'l': 'Рыб',
    };
    const planets: any = {
        'A': 'Солнца',
        'B': 'Луны',
        'C': 'Меркурия',
        'D': 'Венеры',
        'E': 'Марса',
        'F': 'Юпитера',
        'G': 'Сатурна',
        'H': 'Урана',
        'I': 'Нептуна',
        'J': 'Плутона',
        'K': 'Плутона',
        'L': '',
        'M': '',
        'N': 'Земли',
        'O': 'Земли',
        'R': '',
    };
    if (activity.highlight) {
        return `Луна без курса в период времени ${activity.activity}`
    } else {
        if (
            activity.activity.indexOf('→') >= 0 ||
            activity.activity.indexOf('!') >= 0 ||
            activity.activity.indexOf('"') >= 0 ||
            activity.activity.indexOf('#') >= 0 ||
            activity.activity.indexOf('%') >= 0 ||
            activity.activity.indexOf('$') >= 0
        ) {
            const tokens = activity.activity.split(' ');
            const what = tokens[1];
            const target = tokens[2];
            switch (what) {
                case '→': return `Луна переходит в знак ${constellations[target] || '???'}${atTime(activity)}`;
                case '!': return `Соединение Луны и ${planets[target] || '???'}${atTime(activity)}`;
                case '"': return `Оппозиция Луны и ${planets[target] || '???'}${atTime(activity)}`;
                case '#': return `Квадратура Луны и ${planets[target] || '???'}${atTime(activity)}`;
                case '%': return `Секстиль Луны и ${planets[target] || '???'}${atTime(activity)}`;
                case '$': return `Трин Луны и ${planets[target] || '???'}${atTime(activity)}`;
            }
        } else {
            const tokens = activity.activity.split(' ');
            const target = tokens[1];
            return `Луна переходит по прежнему в знаке ${constellations[target]}`;
        }
    }
    return '';
};

const describeMoonPhase = (moonPhase: MoonPhase) => {
    return `Фаза Луны: ${moonPhaseIcon(moonPhase.phase)} (${describePhase(moonPhase.phase)}) в ${moonPhase.time}. ${describeEclipse(moonPhase.eclipse)}`;
}

const describePlanetsActivities = (activities: Activity[]): string => {
    return activities?.map(describePlanetActivity).filter(s => s.length > 0).join('. ') + '.';
}

const describePlanetActivity = (activity: Activity): string => {
    const atTime = (activity: Activity): string => {
        return activity.time? ` в ${activity.time}` : '';
    };
    const constellations: any = {
        'a': 'Овна',
        'b': 'Тельца',
        'c': 'Близнецов',
        'd': 'Рака',
        'e': 'Льва',
        'f': 'Девы',
        'g': 'Весов',
        'h': 'Скорпиона',
        'i': 'Стрельца',
        'j': 'Козерога',
        'k': 'Водолея',
        'l': 'Рыб',
    };
    const planets: any = {
        'A': 'Солнце',
        'B': 'Луна',
        'C': 'Меркурий',
        'D': 'Венера',
        'E': 'Марс',
        'F': 'Юпитер',
        'G': 'Сатурн',
        'H': 'Уран',
        'I': 'Нептун',
        'J': 'Плутон',
        'K': 'Плутон',
        'L': '',
        'M': '',
        'N': 'Земля',
        'O': 'Земля',
        'R': '',
    };
    if (activity.activity.indexOf('→') >= 0) {
        const tokens = activity.activity.split(' ');
        const source = tokens[0];
        const what = tokens[1];
        const target = tokens[2];
        switch (what) {
            case '→': return `${planets[source] || '???'} переходит в знак ${constellations[target] || '???'}${atTime(activity)}`;
        }
    } else if (activity.activity.indexOf('[D]') >= 0) {
        const tokens = activity.activity.split(' ');
        const source = tokens[0];
        return `${planets[source] || '???'} - прямое движение${atTime(activity)}`
    } else if (activity.activity.indexOf('[R]') >= 0) {
        const tokens = activity.activity.split(' ');
        const source = tokens[0];
        return `${planets[source] || '???'} - ретроградное движение${atTime(activity)}`
    }
    return '';
}

export default DayDetailsPopup;