import React, {useState, useEffect} from 'react';
import { getSubscriptionEndDate } from '../../services/SubscriptionService'
import { formatDate } from '../../utils/DateUtils'
import { updatePageTitle } from '../../utils/PageUtils';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

const SubscriptionView = (): JSX.Element => {
    const [endDate, setEndDate] = useState<Date | null>(null);
    useEffect(() => {
        (async () => {
            setEndDate(await getSubscriptionEndDate());
        })();
    }, []);
    updatePageTitle('Подписка');

    return (
        <>
            <Typography variant="h5" color="inherit" component="div">
                Подписка
            </Typography>
            <Typography variant="body1" color="inherit" component="div" sx={{fontStyle: 'italic', padding: '2em'}}>
                Подписка дает возможность посмотреть календарь на <b>следующие</b> и <b>предыдущие</b> месяцы, а так же получить доступ к более <b>детальной информации о дне</b>.
                <br />
            </Typography>
            {!!endDate ? subscribed(endDate) : notSubscribed()}
            <Typography variant="h6" color="inherit" component="div"  sx={{padding: '2em'}}>
                Купить/продлить подписку:
            </Typography>
            <Stack spacing={2}>
                {buySubscriptionPanel(endDate, 3, 'на 3 месяца', '0.99')}
                {buySubscriptionPanel(endDate, 6, 'на 6 месяцев', '1.99')}
                {buySubscriptionPanel(endDate, 12, 'на 12 месяцев', '2.99')}
            </Stack>
            <Typography variant="body1" color="inherit" component="div" sx={{fontStyle: 'italic'}}>
                * Вы по прежнему можете пользоваться бесплатной версией, но Вам будет доступен только <b>текущий</b> месяц
            </Typography>
        </>
    );
};

const addMonth = (date: Date | null, month: number): Date => {
    let dt = !!date ? new Date(date) : new Date()
    dt.setMonth(dt.getMonth() + month);
    return dt;
};

const buySubscriptionPanel = (endDate: Date | null, months: number, text: String, price: String): JSX.Element => {
    return (
        <Paper>
            <>
                <Typography variant="body1" color="inherit" component="div">
                    <>{text} (до {formatDate(addMonth(endDate, months))}) за <b>{price} &euro;</b></>
                </Typography>
                <Button>Купить {text}</Button>
            </>
        </Paper>
    );
}

const notSubscribed = () => {
    return (
        <Typography variant="h6" color="inherit" component="div">
            Вы пользуетесь бесплатной версией!
        </Typography>
    )
}

const subscribed = (till: Date) => {
    return (
        <Typography variant="h6" color="inherit" component="div">
            Ваша подписка действует до <Typography variant="h6" color="inherit" component="span" sx={{color: 'red'}}>{formatDate(till)}</Typography>
        </Typography>
    )
}

export default SubscriptionView;