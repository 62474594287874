import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import { RU as IconRU, GB as IconEN } from 'country-flag-icons/react/3x2';
import i18n from '../../i18n';
import {toggles} from '../../features';

const LanguageSelector = (): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [lang, setLang] = useState<string>(i18n.language);
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (selectedLang: string) => {
        setAnchorEl(null);
        setLang(selectedLang);
        i18n.changeLanguage(selectedLang);
    };
    if (!toggles.i18n) {
        return <></>;
    }
    return (
        <>
            <IconButton
                aria-label="language selector"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                {getFlagIcon(lang)}
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => handleClose('ru')}>{getFlagIcon('ru')} &nbsp; Русский</MenuItem>
                <MenuItem onClick={() => handleClose('en')}>{getFlagIcon('en')} &nbsp; English</MenuItem>
              </Menu>            
        </>
    );
};

const getFlagIcon = (lang: string): JSX.Element => {
    const asIcon = (lang: string): JSX.Element => {
        switch (lang) {
            case 'ru': return <IconRU />
            case 'en': return <IconEN />
            default: return <></>
        }
    };
    return (
    <SvgIcon>
        {asIcon(lang)}
    </SvgIcon>
    )
};

export default LanguageSelector;