import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';

import i18n from '../../i18n';
import {toggles} from '../../features';

const Share = (): JSX.Element => {
    const [linkData] = useState({
        title: i18n.t('share.title'),
        text: i18n.t("share.text"),
        url: 'https://lunarcalendar.app'
    });
    const [available, setAvailable] = useState(false);
    useEffect(() => {
        setAvailable(navigator.canShare && navigator.canShare(linkData));
    }, [linkData]);
    const doShare = () => {
        ReactGA.event({
            category: 'User Engagement',
            action: 'share',
            label: 'Share',
        });
        navigator.share(linkData);
    };
    if (!available || !toggles.share) {
        return <></>
    }
    return (
        <IconButton onClick={doShare} color="inherit">
            <ShareIcon />
        </IconButton>
        
    );
};

export default Share;