import React, {useState, useEffect} from 'react';
import ReactGA from 'react-ga4';
import { withTranslation } from 'react-i18next';
import CalendarDay from './CalendarDay';
import LunarDay from './LunarDay';
import { getDaysArray, mapMoonPhase as moonPhaseIcon, describePhase, describeEclipse } from './Utils';
import { Day } from '../../models/CalendarData';
import { formatDateSimple, weekDays } from '../../utils/DateUtils';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import DayDetailsPopup from '../DayDetailsPopup';
import './Calendar.css'

interface Props {
    year: number;
    month: number;
    daysData: Day[];
}

const Calendar = (props: Props) => {
    const [days, setDays] = useState<Array<Array<number>>>([]);
    const [currentDate, setCurrentDate] = useState<number>(0);
    const [isDetailsPopupOpen, setDetailsPopupOpen] = useState(false);
    const [popupDay, setPopupDay] = useState<Day | null>(null);
    useEffect(() => {
        setDays(getDaysArray(props.year, props.month))
        setCurrentDate(new Date().getDate());
    }, [props.year, props.month]);

    const now = new Date();
    const isCurrentMonth = (now.getFullYear() === props.year) && (now.getMonth() === props.month - 1);

    const onDayDetailsOpen = (day: Day) => {
        ReactGA.event({
            category: 'User Engagement',
            action: 'open_day_details',
            label: 'Open day details',
        });
        setPopupDay(day);
        setDetailsPopupOpen(true);
    };

    const onDayDetailsClose = () => {
        setDetailsPopupOpen(false);
    };

    return (
        <div>
            <table className='calendar-table'>
                <thead>
                    <tr>
                        {weekDays().map(wd => <th key={wd}>{wd}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {mapToWeeks(days, currentDate, isCurrentMonth, props.daysData, onDayDetailsOpen)}
                </tbody>
            </table>
            {moonPhases(props.daysData, props.month)}
            {showDayDetailsPopup(isDetailsPopupOpen, props.year, props.month, popupDay, onDayDetailsClose)}
        </div>
    );
};

const mapToWeeks = (days: Array<Array<number>>, currentDate: number, isCurrentMonth: boolean, daysData: Array<Day>, onDetailsClick: (day:Day) => void): Array<JSX.Element> => {
    return days.map((week, index) => mapToWeek(week, index, currentDate, isCurrentMonth, daysData, onDetailsClick)).flat();
};

const mapToWeek = (week: Array<number>, index: number, currentDate: number, isCurrentMonth: boolean, daysData: Array<Day>, onDetailsClick: (day:Day) => void): Array<JSX.Element> => {
    return [
        (
        <tr key={`week-${index}`}>
            {mapToDays(week, currentDate, isCurrentMonth, daysData, onDetailsClick)}
        </tr>
        ), 
        (
        <tr key={`week-lunar-${index}`}>
            {mapToLunarDays(week, daysData)}
        </tr>
        )
    ];
};

const mapToDays = (days: Array<number>, currentDate: number, isCurrentMonth: boolean, daysData: Array<Day>, onDetailsClick: (day:Day) => void): Array<JSX.Element> => {
    return days.map((day, index) => mapToDay(day, index, currentDate, isCurrentMonth, daysData, onDetailsClick));
};

const mapToDay = (day: number, index: number, currentDate: number, isCurrentMonth: boolean, daysData: Array<Day>, onDetailsClick: (day:Day) => void): JSX.Element => {
    const dayData = daysData.find(d => d.day === day) || {} as Day;
    const isHoliday = index >= 5 || dayData.holiday;
    const isToday = isCurrentMonth && day === currentDate;
    return (
        <td key={`day-${index}`} className={isToday ? 'today' : ''}>
            <CalendarDay 
                day={day} 
                isCurrent={isToday} 
                isHoliday={isHoliday} 
                data={dayData} 
                onDetailsClick={onDetailsClick}
            />
        </td>
    );
};

const mapToLunarDays = (days: Array<number>, daysData: Array<Day>): Array<JSX.Element> => {
    return days.map((day, index) => mapToLunarDay(day, index, daysData));
};

const mapToLunarDay = (day: number, index: number, daysData: Array<Day>): JSX.Element => {
    const dayData = daysData.find(d => d.day === day) || {} as Day;
    return (
        <td key={`day-${index}`}>
            <LunarDay day={day} data={dayData} />
        </td>
    );
}

const moonPhases = (daysData: Array<Day>, month: number): JSX.Element => {
    return (
        <>
            {daysData.filter(d => d.day <= 31 && !!d.moon_phase && !!d.moon_phase.phase).map(d => mapMoonPhase(d, month))}
        </>
    );
};

const mapMoonPhase = (day: Day, month: number): JSX.Element => {
    let text = `${moonPhaseIcon(day.moon_phase.phase)} (${describePhase(day.moon_phase.phase)}) - ${formatDateSimple(null, month - 1, day.day)} в ${day.moon_phase.time}. ${describeEclipse(day.moon_phase.eclipse)}`;
    return <div key={`moon-phase-${day.day}`} style={{textAlign: 'left'}}>{text}</div>
};

const showDayDetailsPopup = (isOpen: boolean, year: number, month: number, day: Day | null, onClose: () => void): JSX.Element => {
    if (!day) {
        return <></>;
    }
    return (
        <Modal open={isOpen} onClose={onClose}>
            <Box>
                <DayDetailsPopup year={year} month={month} day={day} />
            </Box>
        </Modal>
    );
};


export default withTranslation()(Calendar);