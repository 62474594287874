import i18n from '../i18n';

const getLocale = (): string => {
    switch (i18n.language) {
        case 'ru': return 'ru-RU';
        case 'en': return 'en-GB';
    }
    return 'ru-RU';
}

const formatDate = (date: Date, includeYear: boolean = true): string => {
    return formatDateSimple(date.getFullYear(), date.getMonth(), date.getDate())
}

const formatDateSimple = (year: number | null, month: number, day: number): string => {
    return new Intl.DateTimeFormat(getLocale(), {
        year: !!year ? 'numeric' : undefined,
        month: 'long',
        day: 'numeric'
    }).format(new Date(year || 2024, month, day));
}

const formatMonth = (year: number, month: number): string => {
    return new Intl.DateTimeFormat(getLocale(), {
        year: 'numeric',
        month: 'long'
    }).format(new Date(year, month - 1, 1));
};

const weekDays = (): string[] => {
  const { format } = new Intl.DateTimeFormat(getLocale(), { weekday: 'short' });
  return [0, 1, 2, 3, 4, 5, 6]
    .map((day) => format(new Date(Date.UTC(2021, 5, day))));
}

const calculateNext = (year: number, month: number, delta: number): {year: number, month: number} => {
    if (month + delta < 1) {
        return {year: year - 1, month: 12};
    } else if (month + delta > 12) {
        return {year: year + 1, month: 1};
    } else {
        return {year: year, month: month + delta};
    }
}

export { formatDate, formatDateSimple, formatMonth, calculateNext, weekDays };