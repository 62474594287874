import { getSubscriptionEndDate } from './SubscriptionService';
import { toggles } from '../features';

const canViewMonth = async (year: number, month: number): Promise<boolean> => {
    if (!toggles.subscriptions) {
        return true;
    }
    const now = new Date();
    if (year === now.getFullYear() && month === now.getMonth() + 1) {
        return true;
    }
    const endDate = await getSubscriptionEndDate();
    if (!endDate) {
        return false;
    }
    if (now <= endDate) {
        return true;
    }
    return false;
};

const canViewDetails = async (year: number, month: number, day: number) => {
    return canViewMonth(year, month);
}

export { canViewMonth, canViewDetails };