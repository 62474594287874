import React, {useState} from 'react';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Modal from '@mui/material/Modal';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import i18n from '../../i18n';

const InstallCard = (): JSX.Element => {
    const [visible, setVisible] = useState(false);
    const [installPrompt, setInstallPrompt] = useState<any>(null);
    window.addEventListener("beforeinstallprompt", (event) => {
        event.preventDefault();
        setInstallPrompt(event);
        setVisible(true);
    });
    const install = async () => {
        if (!installPrompt) {
            return;
        }
        const result = await installPrompt.prompt();
        console.log('install finished as', result)
        ReactGA.event({
            category: 'User Engagement',
            action: 'install_' + (result ? 'ok' : 'fail'),
            label: 'Installation ' + (result ? 'ok' : 'fail'),
        });
        setVisible(false);
        setInstallPrompt(null);
    };
    const close = () => {
        ReactGA.event({
            category: 'User Engagement',
            action: 'close_install_popup',
            label: 'Installation popup closed',
        });
        setVisible(false);
        setInstallPrompt(null);
    };

    if (!visible) {
        return <></>
    }
    return (
        <Modal 
            sx={{position: "absolute", bottom: 10, left: 10, top: 10, right: 10, maxWidth: '50%', minWidth: '25%'}}
            open={visible}
            onClose={close}
        >
            <Box>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant='h6'>
                            {i18n.t("install.header")}
                        </Typography>
                        <Typography variant='body2'>
                            {i18n.t("install.body")}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" onClick={install}>{i18n.t("install.install_button")}</Button>
                        <Button size="small" onClick={close}>{i18n.t("install.not_now_button")}</Button>
                    </CardActions>
                </Card>
            </Box>
        </Modal>
    );
};

export default withTranslation()(InstallCard);